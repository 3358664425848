import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import sampleBlog from '../assets/sampleblog.svg';
import Author from '../assets/author.svg';
import HTMLReactParser from 'html-react-parser';

function Blog() {

    const navigate = useNavigate();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const { id } = useParams();

    const blogs = [
       {
            poster: sampleBlog,
            title: "The Codification of Bias: How Algorithms Affect Black Candidates Looking for Jobs",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 24, 2022",
        },
        {
            poster: sampleBlog,
            title: "7 Ways to Find a Job as a Black Professional:Career Advice for Black Professionals",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 17, 2022",
        },
        {
            poster: sampleBlog,
            title: "The Embedded Element of Slavery in American Companies",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 11, 2022",
        },
        {
            poster: sampleBlog,
            title: "7 Shocking Facts You Need to Know About Black Diversity and Inclusion in the Workplace",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 11, 2022",
        },
        {
            poster: sampleBlog,
            title: "7 Useful Tips From Experts on How To Find A Job In 30 Days",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 24, 2022",
        }
    ]

    const blogContent = [
        {
            title: 'The Codification of Bias: How Algorithms Affect Black Candidates Looking for Jobs',
          //  poster: sampleBlog,
            content: `<p><img src="https://lh4.googleusercontent.com/Qlt5S5hD_jjvcyBsK9NbUqpe5_OHfYVSZA_KvJiCKFZ4tJiYTu_hk0CbXc2FHYE89pa8OOL2JioEh2iCJgHfAzZqAoYGdUVRL9Jca4s8t-Ijm1Qt2VhC9vOsFwdRp0oc20h92lX4" width="602" height="376" style="margin-left: auto; margin-right: auto; display: block; "></p>
            <p>In a perfect world, algorithms would be unbiased and fair. However, the reality is that algorithms are often biased against certain groups of people. One particularly troubling example of this is the way that algorithms currently affect Black candidates looking for jobs.&nbsp;</p>
            <p>Due to the codification of bias, Black candidates often have a harder time getting hired than other candidates. In this article, we will explore how algorithms can be biased against Black candidates and what can be done to address this issue.</p>
            <h2 className="font-bold text-2xl my-3">Black Candidates Are Often Screened Out by Algorithms</h2>
            <p>When employers use Applicant Tracking Systems (ATS) to screen job applicants, they input certain keywords that they want to see in a candidate&apos;s resume. However, these keywords are often biased against Black candidates.&nbsp;</p>
            <p>For example, a <a className="underline text-blue-500" href="https://scholar.harvard.edu/files/pager/files/annals_sequencingdisadvantage.pdf">recent study</a> found that the most common keyword associated with Black job applicants is &quot;urban.&quot; This keyword is significantly less likely to be associated with White job applicants. As a result, Black candidates are more likely to be screened out by algorithms when employers use ATS.</p>
            <p>One way to overcome this problem is by using an ATS that includes a &quot;diversity&quot; setting. This setting allows employers to input keywords that are more likely to be associated with Black candidates. As a result, Black candidates are more likely to be included in the pool of applicants that employers consider for a job.</p>
            <p>Another way to overcome this problem is by using a human resource professional to screen job applicants. This person can look at each applicant&apos;s qualifications without relying on biased keywords.</p>
            <h2 className="font-bold text-2xl my-3">Algorithms Can Also Be Biased in The Way They Assess Qualifications</h2>
            <p><img src="https://lh3.googleusercontent.com/Xz52FrrziUn4ZaZj7J57duTmhLcNdvOaNw4s_wly_I5cYf2CIZC8Bq-7y4_KS4FRnJ1Pp_zkrTZnJ6KAV7JhOdjM1i5lAvC7IYu0uOrxjC-E5IrQmObBsbNeMLpRTtx4aBMR6R1w" width="602" style="float: right; text-align: right; display: inline-block; width: 462px; height: 288.75px;">In addition to screening out Black candidates, algorithms can also be biased in the way they assess qualifications. For example, a <a className="underline text-blue-500" href="https://hbswk.hbs.edu/item/minorities-who-whiten-job-resumes-get-more-interviews">study by researchers at Harvard</a> and Boston University found that when resumes are assessed by algorithms, Black candidates are less likely to be rated as qualified for a job.&nbsp;</p>
            <p>There are a few ways to overcome this problem. You can use an algorithm that takes into account a candidate&apos;s &quot;soft skills.&quot; Soft skills are personal qualities that are not easily measured by algorithms, but they can be important in predicting job performance. For example, a person&apos;s ability to communicate and work in a team.&nbsp;</p>
            <p>Another way to overcome this problem is by using a human resource professional to assess job applicants. This person can look at each applicant&apos;s qualifications without relying on an algorithm.&nbsp;</p>
            <h2 className="font-bold text-2xl my-3">Algorithms Can Be Biased Against Black Candidates in The Way, They Match Them with Jobs</h2>
            <p>In addition to screening out Black candidates and assessing their qualifications, algorithms can also be biased in the way they match them with jobs. For example, a <a className="underline text-blue-500" href="https://www.econstor.eu/bitstream/10419/73796/1/IfoWorkingPaper-147.pdf">study by researchers</a> found that when job seekers use online job search engines, they are more likely to be matched with jobs that are of lower quality.&nbsp;</p>
            <p>There are a few ways to overcome this problem. One way is by using an online job search engine that allows users to input their own keywords. This way, job seekers can control which jobs they see and are more likely to find a good match.&nbsp;</p>
            <p>Another way to overcome this problem is by using a human resource professional to match job seekers with jobs. This person can look at each job seeker&apos;s qualifications and match them with the most appropriate jobs.&nbsp;</p>
            <h2 className="font-bold text-2xl my-3">What Are the Causes of These Biases?</h2>
            <p>There are a few possible causes of these biases. Some of the causes may be due to the way algorithms are designed. For example, the keywords that employer&rsquo;s input into Applicant Tracking Systems (ATS) can be biased against Black candidates.&nbsp;</p>
            <p>In addition, some of the causes may be due to the data that is used to train algorithms. For example, if a job search engine is trained on data that is mostly from White job seekers, then the algorithm may be more likely to match Black job seekers with lower-quality jobs.&nbsp;</p>
            <p>Finally, some of the causes may be due to human biases. For example, if a human resource professional relies on an algorithm to assess job applicants, they may be more likely to unconsciously bias against Black candidates.&nbsp;</p>
            <h2 className="font-bold text-2xl my-3">What Can Be Done to Reduce These Biases?</h2>
            <p><img src="https://lh3.googleusercontent.com/VjZFPz1t71_AC1j6OF25y7AC6NevbC-FjhcDieqZGWwHSXhwuND9baUFck2_SY67V0cNDgDy0K40VkQfS97Bc_Tfiif19PmoUy3JX62K3fF6z7Y-3OyZD-S9gEYYP6G0ZY6tnxdb" width="602" style="float: right; text-align: right; display: inline-block; width: 468px; height: 292.306px;">There are a few things that can be done to reduce these biases. One is to create awareness of the issue and the other is to design better algorithms.</p>
            <p>Creating awareness can be done through educational initiatives and by working with the companies that create these algorithms. Educational initiatives can help people understand how bias can be codified into an algorithm and how that affects people&apos;s lives. Working with the companies that create these algorithms can help to ensure that they are designed in a way that reduces bias.</p>
            <p>Better algorithms can be designed by taking into account the factors that lead to bias. For example, if an algorithm is used to match candidates with jobs, it can be designed to take into account factors such as experience, education, and skills. By taking these factors into account, the algorithm can reduce the chances of bias.</p>
            <p>The issue of bias in algorithms is an important one that needs to be addressed. By taking steps to create awareness and to design better algorithms, we can reduce the effects of bias on Black candidates looking for jobs.</p>
            <h2 className="font-bold text-2xl my-3">Importance Of Solving These Problems Right Away</h2>
            <p>The issue of bias in algorithms is an important one because it can have a significant impact on people&apos;s lives. The biases that are codified into algorithms can affect people&apos;s ability to get jobs, and this can have a ripple effect on their lives.&nbsp;</p>
            <p>For example, if someone is unable to get a job because of bias in an algorithm, they may have difficulty paying their bills, providing for their family, or achieving their goals. This can lead to a cycle of poverty and despair. Therefore, it is important to take steps to reduce these biases as soon as possible.</p>
            <h2 className="font-bold text-2xl my-3">Conclusion</h2>
            <p>In conclusion, the issue of bias in algorithms is an important one that needs to be addressed. By taking steps to create awareness and to design better algorithms, we can reduce the effects of bias on Black candidates looking for jobs. This will help ensure that everyone has an equal opportunity to find a good job.</p>
            <p><a className="underline text-blue-500" href="https://www.blackuptechnologies.com/">BlackUp</a> is a solution for the bias that exists in the hiring process for Black candidates. By using BlackUp, job seekers can connect with employers who are looking to hire Black employees. This way, they can avoid the bias that exists in the hiring process today. If your company is seeking to use an unbiased software algorithm to accelerate the &nbsp;hiring of Black professionals. Feel free to reach out!</p>`
         },
        {
            title: '7 Ways to Find a Job as a Black Professional:Career Advice for Black Professionals',
            poster: sampleBlog,
            content: `<p>For Black professionals, the job search process can often be daunting and frustrating. You may feel like you are up against insurmountable odds when it comes to finding a job that is a good fit for your skills and experience. But don&apos;t give up! There are plenty of ways to find a job as a Black professional. In this article, we will discuss some of the best strategies for finding employment in today&apos;s competitive market.</p>
            <p><br></p>
            <h2 className="font-bold text-2xl my-3">1. Network<img src="https://lh5.googleusercontent.com/YQMl0WY9kKg2c3uYddYFUjRllfKZm8WIwWTw83yIJHkajIa1AGRfL-1LQ4SIDFURDkdJtZQhx7E7xoGV4Pr-rue314kCY7WLkSrW9cyyw6Yoyl5AkGj91E3UL-FwRjEI1ChrNRYO" width="438" height="292" style="float: right; display: inline-block; text-align:right;"></h2>
            <p>One of the best ways to find a job is by networking with friends, family, and colleagues. Ask them if they know of any openings at their company or if they can refer you to someone who might be hiring. You can also join professional organizations and attend industry events where you can meet potential employers face-to-face.</p>
            <p>According to a study by the U.S. Bureau of Labor and Statistics,<a className="underline text-blue-500" href="https://www.business2community.com/human-resources/85-of-jobs-are-secured-via-networking-heres-how-to-do-it-right-02368331#:~:text=The%20U.S.%20Bureau%20of%20Labor,jobs%20are%20filled%20by%20networking.">&nbsp;85%</a> of jobs are filled through networking. So don&apos;t be afraid to reach out to your contacts and ask for help in finding a job.</p>
            <p>The best way to network is by building relationships with people and being proactive about sharing your skills and experience. Don&apos;t be shy about letting people know that you are looking for a job. The more people who know about your job search, the better your chances of finding a position that is perfect for you.</p>
            <h2 className="font-bold text-2xl my-3">2. Use Job Boards</h2>
            <p>Another great way to find a job is by using job boards. There are a number of different job boards out there, and each one specializes in different types of jobs. You can find job boards for specific industries, geographical areas, or company sizes.</p>
            <p>The best way to use job boards is to create a profile that highlights your skills and experience. Make sure to target your resume and cover letter to the specific job you are applying for. You should also be prepared to answer tough interview questions.</p>
            <p>Job boards can be a great way to find jobs that are not advertised publicly. In many cases, companies will post their openings on job boards before they make them public. So don&apos;t rule out job boards just because you haven&apos;t heard of the company. They may have just the perfect job for you.</p>
            <h2 className="font-bold text-2xl my-3">3. Use Social Media</h2>
            <p>Social media is another great way to find a job. In fact, a study by<a className="underline text-blue-500" href="https://www.cnet.com/tech/services-and-software/heads-up-linkedin-users-93-of-recruiters-are-looking-at-you/#:~:text=A%20recent%20survey%20from%20Jobvite,growing%20in%20influence%20as%20well.">&nbsp;Jobvite</a> found that 93% of recruiters use social media to screen candidates. So if you aren&apos;t using social media to look for a job, you are missing out on a lot of opportunities.<img src="https://lh4.googleusercontent.com/GeIew61LCSJblCzHYselnfzUE3hc19C05N1MTfEzJFxmgBHozox507LheBSIJYLbW67Xdl1d8Eevm192h7VUxGpNe7_ftTOe0AjbJ6qg_y1d1h7lDXwuIzYZdTuqF48Owc2r3_OA" width="493" height="328" style="float: right; display: inline-block; text-align:right;"></p>
            <p>The best way to use social media is to create a profile that highlights your skills and experience. Make sure to target your resume and cover letter to the specific job you are applying for. You should also be prepared to answer tough interview questions.</p>
            <p>Social media can also be a great way to connect with potential employers. You can send them a direct message, or you can post on their wall and ask for advice about finding a job. Don&apos;t be afraid to reach out to people who work at the company you want to work for. They may be able to help you get your foot in the door.</p>
            <h2 className="font-bold text-2xl my-3">4. Use Employment Agencies</h2>
            <p>Employment agencies can be a great way to find a job. They are especially helpful if you don&apos;t have a lot of experience or if you are looking for a job in a specific industry.</p>
            <p>The best way to use an employment agency is to make sure you trust them. Do your research and ask around to see if anyone has had a good experience with them. Once you&apos;ve found an agency that you trust, be prepared to interview with them. They will want to know about your skills and experience, as well as your job goals.</p>
            <p>Employment agencies can be a great way to find jobs that are not advertised publicly. In many cases, companies will post their openings on job boards before they make them public.</p>
            <h2 className="font-bold text-2xl my-3">5. Use Personal Websites<img src="https://lh3.googleusercontent.com/PhslTT1xVZSkxoBTfpJ2mw2Ga9wgDiddvm1s6zgvQgY3akyXGxDb7KJrR45Gbhz_xZvn4buyr76Gq1YjGC5yCW8rqZI_dBGpcWfzEyCWNhaAqjrzBz9yJ1NL39k476rcJdkYxH_m" width="496" height="330" style="float: right; display: inline-block; text-align:right;"></h2>
            <p>If you are looking for a job, it&apos;s a good idea to create a personal website. A personal website can be a great way to promote your skills and experience to potential employers.</p>
            <p>The best way to create a personal website is to use a platform like WordPress or Wix. These platforms make it easy to create a website without any coding knowledge. You can also use them to create a resume, portfolio, or blog.</p>
            <p>Your website should be designed to help you stand out from the competition. It should highlight your skills and experience, and it should be easy to navigate. Make sure to include a contact form so potential employers can reach out to you.</p>
            <h2 className="font-bold text-2xl my-3">6. Attend Job Fairs</h2>
            <p>Job fairs are another great way to find a job. They are especially helpful if you are looking for a job in a specific industry.</p>
            <p>The best way to attend a job fair is to do your research first. Find out which companies will be attending the job fair, and make a list of the jobs you are interested in. Then, create a resume and cover letter that target those specific jobs.</p>
            <p>You should also practice your elevator pitch. An elevator pitch is a concise speech that explains who you are and what you do. It should be no more than 30 seconds long.</p>
            <p>Job fairs can also be a great way to network with potential employers. You can meet people from companies that you are interested in working for, and you can learn about the hiring process.</p>
            <h2 className="font-bold text-2xl my-3">7. Attend Networking Events</h2>
            <p>Networking events can be a great way to find a job. They are especially helpful if you are looking for a job in a specific industry.</p>
            <p>The best way to attend a networking event is to do your research first. Find out which companies will be attending the networking event, and make a list of the jobs you are interested in. Then, create a resume and cover letter that target those specific jobs.</p>
            <p>Networking events can also be a great way to make connections with potential employers. You can meet people from companies that you are interested in working for, and you can learn about the hiring process.</p>
            <p>Make sure to bring business cards so you can exchange contact information with potential employers. And don&apos;t forget to follow up with them after the event!</p>
            <h1 className="font-bold text-2xl my-3">Final Words</h1>
            <p>These are just a few of the many ways that you can find a job. If you want to be successful, you need to be flexible and willing to try new things. The best way to find a job is to network with people who work at the company you want to work for. You can also attend job fairs and networking events. There are many websites that can help you find a job.</p>
            <p>Looking for a job can be difficult, and it can be especially difficult for Black candidates. Sending hundreds of job applications and not hearing back, or going through a couple of interviews only to be told that you were not hired for the role, can be demoralizing. That is why we created a software to help you in your efforts to find a job. If you&apos;re looking for a job and you are a Black professional, our platform will help connect you with companies looking to hire Black talent.<a className="underline text-blue-500" href="https://www.blackuptechnologies.com/">&nbsp;</a>BlackUp is a tech-engineered platform that uses machine learning of artificial intelligence to connect Black candidates to companies that are genuinely prioritizing the hiring of Black professionals. We have a network of contacts at some of the best companies in the country, and we can help connect you to our network of diversity-focused companies. Visit&nbsp;<a className="underline text-blue-500" href="https://blackuptech.com/">https://blackuptech.com/</a> today to learn more!</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>`
        },
        {
            title: 'The Embedded Element of Slavery in American Companies',
            poster: sampleBlog,
            content: `<p>Analysis reported in August 2020 by USA TODAY shows that what many companies claimed to stand and speak against racism in the workplace &ndash; especially at the top management of companies during the George Floyd protests &ndash; were mere hollow statements. Many mega-companies are still led by all-white executives in the top five slots listed on proxy statements: &nbsp;the CEO, the CFO, and other top-paid executives, such as the CTO, CIO, CPO, or members of the board of directors. As of July 2020, of 279 top executives listed at the 50 biggest companies of the S&amp;P 500, only 5 were black, and 2 of them had recently retired. &quot;Business and diversity scholars say the executive suite is still one of America&rsquo;s most exclusive and impenetrable clubs, with the corporate hierarchy most closely resembling a plantation: Heavily white at the top. Black labor struggling to move up.&quot;</p>
            <p>Let&rsquo;s take a company like Nike, that has built its success on the backs of Black athletes. Nike&rsquo;s advertisement, which is designed with the goal to encourage Americans to &quot;Just Do It&rdquo;, celebrates disabled athletes, female participation in sports, and Colin Kaepernick, the outcast NFL player who protested racial injustice in 2016; it delights in preaching about inclusion, diversity, and equality in the workplace, and their Nike Jordan brand donated $40 million over four years to Black communities (CNBC June 2020), with a promise to continue to strive as a role model of a company that promotes diversity, all during the protest against Floyd&rsquo;s murder by a White police officer. Nike&rsquo;s board and management has finally expanded to include Black individuals on their board and top management positions only in the last 5 years. One would wonder why it took so long for a company that has, since 1988, taught us through their ads to be better and do better. The sad question is this: if a company, that has entirely depended on Black talent, grace, beauty and excellence in their successful ads for decades, can be so far behind the road to equality, and demonstrates engrained systems of slave mentality itself, what do we expect from others? It should be recognized that Nike has recruited Thasunda Duckett, Peter Henry, and John Rogers, Jr. to sit on its board, and Melanie Harris and Scott Uzzell to lead in its top management team in the last 5 years; Nike should be commended for being &ldquo;better late than never&rdquo;. The stark reality is that the majority of companies are still just full of intentions. However, in 2022, some praiseworthy companies moved from the intention phase to the impact phase. Here is a list of some of those companies: https://fortune.com/2021/06/02/fortune-500-companies-diversity-inclusion-numbers-refinitiv-measure-up/</p>
            <h2 className="font-bold text-2xl my-3">The Evolution of The Exploitative Economy for Blacks</h2>
            <p>Most people do not remember that slavery was mainly about labor. Most have forgotten that it was about western countries wanting to take the labor of Africans for free and by force. While it is true today that Africans are no longer paraded in chains to be auctioned to owners who coerced them to work the fields of their White plantation owners, these physical chains of the past are now replaced by modern-day cultural chains that gravely hinder Black workers today. For example, when the government was constructing the Wilbur Dam in 1912 in Tennessee, there was not enough labor to complete the dam before the rains and floods. The government had to rely on Black workers to finish it on time, but the local White businessmen and vigilante groups rejected this arrangement. They obliged only if the government paid the Whites a higher wage than the Blacks. Perpetuating the idea that Black people were less than White people, and that their labor should be undervalued, was a common practice post slavery, especially in the South. Continuing up to 2022, Black workers today are still besieged by heavy and hidden emotional and psychological shackles, which are no less excruciating than the obviously visible and appalling ones of the past. This article will focus on three chains that drag down Black employees: the chains of mental illness, the chains of incarceration, and the chains of pay inequality. &nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <h2 className="font-bold text-2xl my-3">The chains of mental illness</h2>
            <p><br></p>
            <p><img src="https://lh6.googleusercontent.com/SKMBRIJHiSKCeOB0cd3zfgTuJOwjm96VNLJ96e_2LzmrhsumQ5sHncda5s7j6ZFmokLMinHnHEsjL_KtueJQzqNWpoo64_i95dSorqvV7ofKU003OAHVih_r_ejddkjkspVOIF8m" width="662.0000000000001" style="float: right; text-align: right; display: inline-block; width: 422px; height: 242.873px;"></p>
            <p>According to Columbia University Department of Psychiatry, the adult Black community is 20% more likely to experience serious mental health problems: &ldquo;...the Black community, in particular, is at significantly increased risk of developing a mental health issue due to historical, economic, social, political influences that systemically expose the Black community to factors known to be damaging to psychological and physical health&hellip;.Historically, the Black community was and continues to be disadvantaged in mental health through subjection to trauma through enslavement, oppression, colonialism, racism, and segregation. A growing body of research suggests that traumatic experiences can cause profound biological changes in the person experiencing the traumatic event. Cutting edge researchers are also beginning to understand how these physiological changes are genetically encoded and passed down to future generations. These findings suggest that in addition to the cultural and psychological inheritance of trauma, intergenerational trauma may be passed down biologically from one generation to the next.&rdquo; 16% of (4.8 million) Black people reported having a mental illness often due to frequent harassment in the workplace. &ldquo;Trauma is the experience of severe psychological distress following any terrible or life-threatening incident.&rdquo; Studies consistently have shown the multiple traumas that Black people face at work, from getting hired for roles for which they are overqualified, to feelings constantly undervalued, to dealing with bias, code-switching, microaggressions, and job-insecurity. &nbsp;There are extreme and subtle cases of cruelty, disrespect, threats, and unacceptance that wear down blacks in the corporate setting. &nbsp;There&rsquo;s sexual harassment to employed and un-employed black women.</p>
            <p><br></p>
            <h2 className="font-bold text-2xl my-3">The chains of incarceration</h2><img src="https://lh4.googleusercontent.com/1DcA3jNJMBWer4tJBtyx3m9Lj3slZLZVBY4Ft4Gs9ElIOCd--dx6ENHTTBAvR2aDblYMWJHg2B3CcAj3c6eMO55RHWXePg80f65dsZUtU47QiyWG_nacevRzK7l6daVo3l3ECUiL" width="624" style="float: right; text-align: right; display: inline-block; width: 446px; height: 251.184px;">
            <p><br></p>About 33% of African American men are in jail. According to a report published by The Sentencing Project, &ldquo;Black American are incarcerated in state prisons at nearly 5 times the White rate. One in 81 Black adults in the US is serving time in state prison. In 12 states, more than half of the prison population is Black.&rdquo; Black people are only 15% of the population. &nbsp;Systemic issues related to racism, police brutality, and relics of slave mentality are to blame. The lack of job opportunity is a major contributing factor to disadvantage, vulnerability to drugs, crime, and jail time. &ldquo;Innocent Black people are seven times more likely to be wrongfully convicted of murder than innocent white people.&rdquo; <a href="https://innocenceproject.org/facts-racial-discrimination-justice-system-wrongful-conviction-black-history-month/">https://innocenceproject.org/facts-racial-discrimination-justice-system-wrongful-conviction-black-history-month/</a>&nbsp; The cycle of poverty, pain, shame, inhumanity and injustice heaped on Black families continue after incarceration because most companies avoid hiring those with prison records.<p></p>
            <p><br></p>
            <h2 className="font-bold text-2xl my-3">The chains of prostutiton</h2>
            <p><br></p>
            <p><img src="https://lh3.googleusercontent.com/XHS2dLasW9WCe3eEYS4EXEW_2nM5ohmvKr3NpvZOBRZVdqT5NasGptHTkdLzZTk82DEgMQPTATXwtLUmxpercDTg5XyWpHdFjSECjbLQ99KfFUveDoNvqCMv75pdJsc6m8StuJB9" width="654" style="float: right; text-align: right; display: inline-block; width: 421px; height: 280.667px;"></p>
            <p>When a mother must work extra hours because the father is in prison, the children are left alone too long without proper care, nurturing, and adult supervision. Black girls are more likely to experience poverty than their racial counterparts. According to the Congressional Black Caucus Foundation, the Rights 4 Girls, the Institute for Women&rsquo;s Policy Research, and Polaris, intersecting factors like low socioeconomic status, child welfare involvement, detachment from education, criminal justice involvement, and history of physical/sexual abuse contribute to the increased vulnerability of sex trafficking. Rates of sex trafficking are higher among Black women and girls; &nbsp;research indicates that sexualization of Black women and girls has played out since slavery. <a href="https://www.cbcfinc.org/wp-content/uploads/2020/05/SexTraffickingReport3.pdf">https://www.cbcfinc.org/wp-content/uploads/2020/05/SexTraffickingReport3.pdf</a>&nbsp; Due to unemployment, debt, and the need to provide for themselves and their children, many victims are less likely to leave prostitution to make ends meet. An article published in the Voice of Black Cincinnati states that &ldquo;62% of human trafficking suspects are African Americans, 52% of all juvenile prostitution arrests are African Americans, and 40% of victims of human trafficking are African Americans.&rdquo;</p>
            <p><br></p>
            <h2 className="font-bold text-2xl my-3">&nbsp;The chains of discrimination and pay inequality</h2>
            <p>A &nbsp;2019 survey of consumer finances found that Black women only get about $7,000 for every $92,000 that a White man makes, more than a 90 percent wealth gap between a black woman and a white male. &nbsp;When researchers at Goldman Sachs dug deeper into the numbers, they found that 2/3 of the wealth gap was related to earnings &ndash; companies choosing not to pay, or raise pay, or promote Black women equivalently to White men. The brokenness in the Black family can be traced back to the lack of opportunity to work and provide for the family. Educational attainment highly impacts employment and wages. &nbsp;One example of academic discrimination is seen in the numbers and the rate of increase in MBA degrees from 1980 to 2015 for blacks and women: Although African Americans and women account for growing proportions of MBA-holders in the US, rising from 4% in 1990 to 14% in 2015 for African Americans, and from 22% in 1980 to 47% in 2014 for women (National Center for Education Statistics), the disparity is apparent in the meager percentage of degrees granted to African Americans, both male and female, even after 35 years. &nbsp;The Business Insider reported, on Jun 18, 2020, that Black Americans still comprise under 10% of students in MBA programs on average, and that number hasn&apos;t grown over the past decade. <a href="https://www.businessinsider.com/black-students-mba-business-school-number-hasnt-increased-decade-harvard-2020-6">https://www.businessinsider.com/black-students-mba-business-school-number-hasnt-increased-decade-harvard-2020-6</a></p>
            <p><br></p>
            <h2 className="font-bold text-2xl my-3">4 Ways Companies can Abolish Exploitation of Black Workers</h2>
            <p>Companies are spending over 10 billion dollars on diversity training that is producing paltry results. Actions always speak louder than words. &nbsp;Real solutions are needed, such as Black pay equality, fair access to job opportunities, at least one Black professional in a top management position, and fair career-development opportunities.</p>
            <p><strong>Publish Pay Scale:</strong> &nbsp;When my parents worked for the government in Nigeria, they were always excited to see the yearly published salary structure, which was based on grade level. Workers knew what they and their colleagues made. There was pay equality. Women and men were paid based on their education level and the companies they worked for. It was never based on familiarity of other social factors that benefited one over another. In a country like the US where racism is well-documented, blacks get paid less, those with fewer connections get paid less. Companies can eliminate that disparity and favoritism by publishing their pay scale, or at least posting the pay range for every job. That single act would give people confidence and create wage equality. When the salary scale is based on the value workers provide to a company, instead of their skin color, gender, or their connections, there will be no more guesswork, no more backroom bias. &nbsp;A fair playing ground will be established for all.&nbsp;</p>
            <p><strong>Measurable Black Equity &amp; Inclusion (BEI) plan</strong>: &nbsp;Non-specific goals are very hard to measure and difficult to achieve. Most companies are talking about diversity, they want to hire more Black professionals and pay them more. The problem is that most companies are stuck at the intention phase, and not successfully moving into the implementation phase. C-Suite Executive and Award-Winning DEI Strategist, Nzinga &ldquo;Zing&rdquo; Shaw, said, &ldquo;Herein lies the reason that so many DEI (diversity, equity, and inclusion) practitioners are not experiencing success in their roles and are now members of The Great Resignation, or worse, The Great Separation Agreement! Unfortunately, there are companies out here &quot;workfishing&quot; smart and dedicated professionals, esp. in the DEI space - post George Floyd. Once the dream is sold, these earnest and honest leaders are terrorized at work when they show up and do their jobs correctly - &nbsp;which includes highlighting inequity in work systems, pay discrepancies, unfair decision-making, poor career development opportunities, and the list goes on. People, please do your homework and don&apos;t be lured by a fancy title (that actually means nothing), a high salary (that you&apos;ll never actually make in 12 months) and the promise of being a &quot;change agent&quot; (for executives that have zero desire to change a thing). Remember, the past is a VERY STRONG indicator of what you can expect in the future....and to all these recruiters, companies, and whoever else is out here workfishing people, remember that what goes around comes around, and that&apos;s a fact!&rdquo; <a href="https://www.linkedin.com/posts/imani-j-carter-3a946466_workfish-dei-stopthegames-activity-6900085936831758336-fNXQ">https://www.linkedin.com/posts/imani-j-carter-3a946466_workfish-dei-stopthegames-activity-6900085936831758336-fNXQ</a></p>
            <p>This phenomenon of &ldquo;workfishing&rdquo; Black professionals just for sparkling PR, without desire to foster actual inclusion in a corporation, is a despicable trend that is demoralizing numerous Change Agents. Many capable DEI strategists have resigned in frustration and exhaustion. The solution is to clearly evaluate, and hold companies accountable to, the DEI progress they aim to accomplish, through a data-focused, measurable BEI scorecard, publicly reportable to the economic market, respecting and supporting the necessary work of these talented DEI practitioners. There needs to be a reliable, uniform standard to measure Black diversity for every American company. The newly engineered BlackUp Technologies platform can provide this crisp, clear, effective evaluation tool that all companies need to embrace to monitor their track record, in order to convert into powerhouses of equality and inclusivity. &nbsp;The time is now to make significant reparations for the harrowing employment tragedies that have festered over the last 400 years. We envision a future in which the high value of unique skills and knowledge of blacks can be unlocked, augmented, and surfaced to consumers.</p>
            <p>Fair Access to Job Opportunities: &nbsp;A study done by BYU professors found that &ldquo;The managerial landscape in many U.S. companies continues to resemble monocultures. Almost 90% of Fortune 500 CEOs are white males, while less than 4% are African American or Hispanic, and less than 6% are women. Among all US companies with 100 or more employees, the proportion of Black men in management barely increased from 3% in 1985 to 3.3% in 2014. This disparity suggests important barriers in the career trajectory for racial minorities. A <a href="http://www.nytimes.com/2015/08/17/business/racial-wealth-gap-persists-despite-degree-study-says.html?_r=0">report&nbsp;</a>by the Federal Reserve Bank of St. Louis shed more light on the fact that even a college degree is not providing the buffer for African American workers to achieve upward mobility in their careers, nor is it providing a great equalizer to economic growth and prosperity. BlackUp Technologies, with its unique AI capabilities, will super-facilitate the matching of burgeoning businesses and black professionals through their online headhunting platform. &nbsp;Employers and employees will find a centralized format at the BlackUp website to access accurate information of ideal work opportunities, amplified job searching, methods to eliminate bias, and ultimately adequate tools to change the hearts and minds of corporate America to stamp out the plague of discrimination against the black community. &nbsp;</p>
            <p><br></p>
            <h2 className="font-bold text-2xl my-3">Conclusion and Solution</h2>
            <p>Dr. King gave his famous speech at The Great March on Washington for Jobs and Freedom in 1963. &ldquo;One hundred years later, the Negro lives on a lonely island of poverty in the midst of a vast ocean of material prosperity. . . . In a sense we&rsquo;ve come to our nation&rsquo;s capital to cash a check . . . that will give us upon demand the riches of freedom and the security of justice.&rdquo; Justice in this sense was both protection from segregationists and access to that &ldquo;vast ocean of material prosperity.&rdquo; Almost 60 years after Dr. King&rsquo;s profound speech, the opportunity to prosper and enjoy the vast wealth is still very elusive for Black people. &nbsp;In another speech, The Other America (1968), King elaborated on the issue of wage and income inequality, saying the struggle for equality includes making sure every American is guaranteed a quality education, a solid job, an annual income, and good housing conditions. Yet King&apos;s hopes and dreams for equal opportunity for working people, especially that of African Americans, have yet to be fulfilled.</p>
            <p>While there has been an evoulution in the awareness and acknowledgement of the exploitation of Black slaves since 1619, progress and improvements to reduce this abuse has been slow and umimpressive. We no longer see White masters ride on horseback, whips in hand, to keep Black slaves toiling in the heat and cold in back-breaking labor to survive. &nbsp;The core of the problem is the woeful deprivation of truth, that all men are created equal. &nbsp;In consequence, Blacks in America have been physically and mentally chained, brutally whipped, constantly demoralized, and viciously murdered, in the false notion of inequality. &nbsp;Corrupt principles, ideologies and practices still endure for many Black people, in the form of false assumptions of inferiority, prejudiced agressions and indignities, and discriminatory and inadequate hiring, payments, and promotion practices. &nbsp;We need to abolish racism in the heart and mind of corporate America. &nbsp;This will vastly improve the economy of this great nation.</p>
            <p>The solution is honest and true evaluation and treatment, all being exhibited in the recognition of Black intelligence, skills, talents, and contributions, in provision of equal access to excellent education. &nbsp;Businesses need to learn how to hire justly, pay wages fairly, ensure safe and &nbsp;worthwhile employment experiences, grant unprejudiced raises and promotions, promote better work, health, and living conditions. The loop of pain can be broken. Sincere and sustained reparations of the hideous past, and current efforts to stop targeting and convicting the innocent, to stop driving hopeless people to crime and violence, to stop squandering the enormous brilliance and talents of the Black race. America&rsquo;s injustices to minorities drastically damages the entire society.&nbsp;</p>
            <p><strong>BlackUp</strong> is a major part of the solution. &nbsp;We&apos;re committed to supporting the Black Community in professional development and success. At BlackUp&rsquo;s online AI platform, we have created a trackable, effective BEI (Black Equity and Inclusion) report card for DEI (Diversity, Equity, Inclusion) agents to objectively use to regularly monitor and inform their companies of their benchmarks in competency and advancement toward inclusivity, diversity, and civility for talented, qualified, experienced Black candidates. &nbsp;Superb ratings will provide genuinely outstanding PR for businesses. &nbsp;BlackUp will also be a uniquely-focused online site for serious employers and Black employees to search and find each other in a streamlined manner. &nbsp;At BlackUp, resources for expert education, accurate information, and effective guidance to eliminate bias, apply integrity, and practice justice, will be found at the website. Ultimately, BlackUp will be your reliable companion to help your thriving business to move on from intention to implementation, to truly change lives for the better, to promote the best future for all.&nbsp;</p>`
        },
        {
            title: '7 Shocking Facts You Need to Know About Black Diversity and Inclusion in the Workplace',
            poster: sampleBlog,
            content: `<p>Did you know that Black people are still overall the most marginalized and discriminated racial group in America? Despite making up over<a className="underline text-blue-500" href="https://www.pewresearch.org/social-trends/fact-sheet/facts-about-the-us-black-population/#:~:text=The%20Black%20population%20of%20the,Black%20Americans%20are%20diverse.">&nbsp;14% of the population</a> as of March 2021, African Americans continue to face significant obstacles in every area of life. From education to employment to housing, the statistics are shocking. In this blog post, we will discuss some of the most troubling facts about Black diversity and inclusion in the workplace.</p>
            <h2 className="text-xl my-3 font-semibold">1. African Americans are more likely to be unemployed than any other racial group.<img src="https://lh6.googleusercontent.com/-oDMCN9aYsocNWM141fEqPYWzDcIieIqrZxkfNepOn9OlAUynukpXD5QzUiEC83k1ZYpQLjcHUig6_dgfoazWeTL2-X0Yksg7mZNw4ISS00dpmZ9_fGubB0mQYSVgh5PqquT09Gy" width="519" height="346" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>The unemployment rate reported in September 2020 shows persistent Black-White unemployment gap.&nbsp;<a className="underline text-blue-500"  href="https://www.americanprogress.org/article/persistent-black-white-unemployment-gap-built-labor-market/">https://www.americanprogress.org/article/persistent-black-white-unemployment-gap-built-labor-market/</a> and over three times the rate for Asian American workers in 2019.&nbsp;<a className="underline text-blue-500" href="https://www.bls.gov/opub/reports/race-and-ethnicity/2019/home.htm">https://www.bls.gov/opub/reports/race-and-ethnicity/2019/home.htm</a>.</p>
            <p>This disparity is even greater for Black males, who had 6.2% less employment in the population than white males in 2020. (<a className="underline text-blue-500" href="https://www.brookings.edu/research/why-are-employment-rates-so-low-among-black-men/">https://www.brookings.edu/research/why-are-employment-rates-so-low-among-black-men/</a> &nbsp;</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <h2 className="text-xl my-3 font-semibold">2. Black workers are more likely to be in low-wage jobs.<img src="https://lh6.googleusercontent.com/GW-HT7bY85sDL8k0I4Qwikp4wkx4J2qWHtkZHAFxxZv2JnMOtZpDEoq1XmNX-WBYBzUuNRn7igahf30IUOBrttVxDJhRJ0etF3auFdd_trNgCd7NvPH4amp-9JflAqvCBpHqEpLM" width="509" height="339" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>African Americans are overrepresented in low-wage jobs, making up nearly half of all workers earning minimum wage or less. This is despite the fact that they account for only<a className="underline text-blue-500" href="https://www.epi.org/publication/black-workers-covid/">&nbsp;13% of the workforce</a>, as of May 2020.</p>
            <h2><br></h2>
            <h2 className="text-xl my-3 font-semibold">3. Black employees are less likely to receive a promotion than their white counterparts.</h2>
            <p>Black employees are promoted at a lower rate than white ones, even when they have the same credentials and experience. According to one study by Pew Research Center, only<a className="underline text-blue-500" href="https://www.pewresearch.org/fact-tank/2016/07/01/racial-gender-wage-gaps-persist-in-u-s-despite-some-progress/">&nbsp;50% of African Americans</a> received promotions compared with 75% of their white colleagues between 2010 and 2013. Black people also tended to be paid less for comparable work than other racial groups.</p>
            <h2 className="text-xl my-3 font-semibold">4. Black employees are more likely to experience discrimination at work.</h2>
            <p>African Americans are more likely than whites to experience<a className="underline text-blue-500" href="https://news.gallup.com/poll/328394/one-four-black-workers-report-discrimination-work.aspx">&nbsp;discrimination in the workplace</a>. A Gallup survey conducted at the end of 2020 revealed that 24% of Black employees had experienced racial discrimination in the form of mistreatments and microagressions in the previous 12 months. &nbsp;</p>
            <h2 className="text-xl my-3 font-semibold">5. African American women are particularly vulnerable to discrimination and sexual harassment.<img src="https://lh3.googleusercontent.com/AiddMEEFno34hkSV3FqYPVvYD9yRFcqHvCbNbkwKWcERJmkpn_gR7dh7k99GvYAkGQ6iqYzbKjbeSEs99jbzLwXWuLvxCRMwCaaMpa0baImK2jLq0Sqhj6LdkOIhk88Aq5eWqA4h" width="506" height="337" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>Black women suffer from unique forms of discrimination and sexual harassment in the workplace. They are more likely than any other group to experience gender and racial bias, and they are also more likely to be sexually harassed.&nbsp;<a className="underline text-blue-500" href="https://nwlc.org/press-release/black-women-disproportionately-experience-workplace-sexual-harassment-new-nwlc-report-reveals/">https://nwlc.org/press-release/black-women-disproportionately-experience-workplace-sexual-harassment-new-nwlc-report-reveals/</a></p>
            <p><br></p>
            <h2 className="text-xl my-3 font-semibold">6. Black employees often face a &quot;double jeopardy&quot; of discrimination.</h2>
            <p>Black employees can experience discrimination based on both race and gender, or alternatively, based on their race and their sexual orientation. This can lead to unique forms of unjust and prejudicial treatment that other groups do not experience as severely. &nbsp;For example, 21% of chief officers in the nation are women, but only 1% of these women are Black. &nbsp;For every 100 men hired into manager roles, only 64 Black women are hired. &nbsp;For every 100 men promoted to manager, only 58 Black women are promoted. &nbsp;47% of Black transgender women report being fired, denied a promotion, or not hired because of their gender identity. &nbsp;<a className="underline text-blue-500" href="https://leanin.org/black-women-racism-discrimination-at-work">https://leanin.org/black-women-racism-discrimination-at-work</a></p>
            <h2 className="text-xl my-3 font-semibold">7. Black employees are less likely to feel supported by their employers.<img src="https://lh6.googleusercontent.com/Cdv-AOYEfczwZnxL7Vk9z0cdKVw8Zitq9-I2ommF3GtAnzTqEutVmlK-QZBiMpbWf-EjgxE7GYTi9ZkmF2X4pd8QKh17b5QgAvBY4O8QvxKNLm6-8Dlj-kCaMfBNqMivqWHlN-yg" width="523" height="348" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>African American employees often do not feel valued by their employers, leading to a<a className="underline text-blue-500" href="https://hbr.org/2019/11/toward-a-racially-just-workplace">&nbsp;high rate of job dissatisfaction</a>. Underrepresentation, plus explicit and subtle racism, biases, intense pressure to prove extreme competence compared to other races to win promotions, isolation and uncertainty, needed to create facades of conformity to be accepted all combine to create inhospitable work environments that make advanced Black employees feel like outsiders.&nbsp;</p>
            <p>Talented, capable, educated Black professionals are also more likely to leave their jobs prematurely due to extreme lack of support and engagement by managers to eliminate discrimination, and due to fewer opportunities to excel and feel accepted on a team, according to the Gallup 2020 survey on discrimination.</p>
            <h1 className="text-xl my-3 font-semibold">Conclusion</h1>
            <p>These are just some of the shocking facts about Black diversity and inclusion in the workplace. It is clear that much work still needs to be done in order for this problem to be solved.</p>
            <p>If you&apos;re looking for a company that promotes the importance of diversity and inclusion, then look no further than&nbsp;<a className="underline text-blue-500" href="https://blackuptech.com/">https://blackuptech.com/</a>. &nbsp;We work with HR professionals, diversity and inclusion leads, and companies that are dedicated to having a more diverse and supported workforce. We understand the importance of these issues and are committed to helping companies that are genuinely making efforts to create an inclusive workplace. Contact us today to learn more about our services!</p>`
        },
        {
            title: '7 Useful Tips From Experts on How To Find A Job In 30 Days',
            poster: sampleBlog,
            content: `<p>If you&apos;re one of the millions of people out of work, you know that finding a job can be a daunting task. But don&apos;t despair - there are things you can do to increase your chances of finding employment in a shorter amount of time. The following seven tips are from career experts who know what it takes to find a job in a short period of time. Utilize their advice and you&apos;ll be on your way to landing the interview that could lead to your dream job!</p>
            <h2 className="font-bold text-2xl my-3">1. Update your resume.<img src="https://lh6.googleusercontent.com/3vDJsQSK5f-zCCnDjgo1a_Li7rDFWNUygwYrFNCE3o4d7srpJ7rlodjb_L34u48hLUcXC07rGFxBgdyR4z0p5ZYH2rbr4DDPPaCtBJfUGCEjKda4t7rmeUQFyCxIVQFQhoF0p4UX" width="471" height="313" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>Your resume is your first opportunity to make a good impression on potential employers, so make sure it&apos;s up-to-date and error-free. Highlight your skills and experience, and make sure to target your resume to the type of job you&apos;re applying for.</p>
            <h3 className="font-bold text-2xl my-3">What are some other tips for creating a winning resume?</h3>
            <p>Some other tips for creating a winning resume include:</p>
            <p>&bull; Use simple, clear language and action verbs.</p>
            <p>&bull; Quantify your achievements with numbers and data.</p>
            <p>&bull; Highlight the most relevant experiences and skills.</p>
            <p>&bull; Use keywords from the job posting.</p>
            <p>The<a href="https://zety.com/blog/hr-statistics">&nbsp;studies</a> are all in agreement: 90% of all resumes are scanned for less than 20 seconds. So make sure your resume is easy to read and highlights your most relevant qualifications!</p>
            <h3 className="font-bold text-2xl my-3">What to include and what to leave out?</h3>
            <p>When deciding what to include on your resume, ask yourself if the experience or skill is relevant to the job you&apos;re applying for. If it&apos;s not, then leave it out. You want your resume to be as concise and focused as possible so that potential employers can see at a glance that you&apos;re a good fit for the job.</p>
            <h2 className="font-bold text-2xl my-3">2. Network, network, network.</h2>
            <p>One of the best ways to find a job is to network with people you know - friends, family, acquaintances, and even former colleagues. Let them know you&apos;re looking for work and ask if they know of any openings or can put in a good word for you.</p>
            <p>Don&apos;t be afraid to ask for help.</p>
            <p>Remember, your network is made up of people who want to see you succeed. They&apos;ll be more than happy to help you if they can. So don&apos;t be afraid to ask for help when you&apos;re job-hunting.</p>
            <h3 className="font-bold text-2xl my-3">How to network better?</h3>
            <p>Some tips for networking better include:</p>
            <p>&bull; Get involved in professional organizations and attend events.</p>
            <p>&bull; Volunteer your time and expertise.</p>
            <p>&bull; Use social media to connect with people in your field.</p>
            <p>According to a study by the<a href="https://udallas.edu/offices/opcd/_documents/Job_Seeker_Guide.pdf">&nbsp;US Bureau of Labor Statistics</a>, about 70% of all jobs are found through networking. So get out there and start connecting with people!</p>
            <h2 className="font-bold text-2xl my-3">3. Use job-search engines.<img src="https://lh3.googleusercontent.com/dryX8S_SOR4RNA1dFw8F_gd5QXc7bUrajlwzFXMSBnPUzvmf1P_z6JAy9VjV9Aehvvdqt3tr2uMa-qUqGtVkZxDRd3h1hPGwVJvn5XrZ4hu4VJSgLkPw-yd7ihv8A_ljg7PkVIJA" width="457" height="304" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>Job search engines are a great way to find openings that match your skills and experience. They can also help you stay up-to-date on the latest job postings.</p>
            <p>Some popular job search engines include:</p>
            <p>&bull; Indeed</p>
            <p>&bull; Monster</p>
            <p>&bull; CareerBuilder</p>
            <p>&bull; LinkedIn</p>
            <h3><br></h3>
            <h3 className="font-bold text-2xl my-3">How to efficiently use job-search engines?</h3>
            <p>When using job search engines, be sure to:</p>
            <p>&bull; Use keywords that describe your skills and experience.</p>
            <p>&bull; Set up alerts for new postings.</p>
            <p>&bull; Research the companies you&apos;re interested in.</p>
            <p>The recent pandemic has resulted in a lot of job losses. But don&apos;t despair - there are still plenty of opportunities out there. Use job search engines to find them!</p>
            <h2 className="font-bold text-2xl my-3">4. Get your resume in front of the right people.<img src="https://lh4.googleusercontent.com/b9FulHdRpIpP71WEZtLsafcfi5AX9G0kAmrWASoGLKCJhvkDfSUBVhpKiJgdV2jJcGTynLjMAtlDxJbL-l5wl41gTdpcp07ZmuSnTjcNyH8_28HqY3DofAfCNfJ6KiWMCScovSIx" width="441" height="294" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>Sometimes the best way to find a job is to get your resume in front of the right people. If you know someone who works at a company you&apos;re interested in, ask them to pass your resume along. You can also submit your resume online directly to the company&apos;s website.</p>
            <h3 className="font-bold text-2xl my-3">How to get your resume in front of the right people?</h3>
            <p>Some tips for getting your resume in front of the right people include:</p>
            <p>&bull; Research the company and target your resume accordingly.</p>
            <p>&bull; Use a professional resume writer to help you create an eye-catching resume.</p>
            <p>&bull; Connect with the company on social media.</p>
            <p>Recent studies have shown that about<a className="underline text-blue-500" href="https://www.linkedin.com/pulse/new-survey-reveals-85-all-jobs-filled-via-networking-lou-adler">&nbsp;85% of all jobs</a> are filled through networking. So get out there and start connecting with people!</p>
            <h2 className="font-bold text-2xl my-3">5. Attend job fairs and career events.</h2>
            <p>Job fairs and career events are a great way to meet potential employers and learn more about the jobs that are available. They&apos;re also a great opportunity to network with other job seekers.</p>
            <h3 className="font-bold text-2xl my-3">What to bring to a job fair?</h3>
            <p>When attending a job fair, be sure to bring:</p>
            <p>&bull; A copy of your resume.</p>
            <p>&bull; A list of questions for potential employers.</p>
            <p>&bull; A positive attitude and a lot of energy.</p>
            <p>According to<a className="underline text-blue-500" href="https://press.careerbuilder.com/2018-08-09-More-Than-Half-of-Employers-Have-Found-Content-on-Social-Media-That-Caused-Them-NOT-to-Hire-a-Candidate-According-to-Recent-CareerBuilder-Survey">&nbsp;CareerBuilder</a>, nearly 70 percent of employers are more likely to hire a candidate they meet at a job fair. So, if you&apos;re serious about finding a job, job fairs should be on your radar.</p>
            <h2 className="font-bold text-2xl my-3">6. Practice your interview skills.<img src="https://lh3.googleusercontent.com/yOLFlKX0iscwRoow3uAgjo9uvgLHK4rT3ZiFHkHZupGUkC48aWEU6IGvUJpm4M46mm8IIezgEVORfpbpxzl3EBDCGX277nVBc2AG0GIdce5tMnkSJuGl3S2WWlAkFrq8x__RHCPU" width="460" height="306" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>One of the best ways to increase your chances of getting the job is to practice your interview skills. Interviewing can be nerve-wracking, but if you prepare ahead of time, you&apos;ll be more confident and ready for anything.</p>
            <h3 className="font-bold text-2xl my-3">What to do before an interview?</h3>
            <p>Some tips for preparing for an interview include:</p>
            <p>&bull; Research the company and the position.</p>
            <p>&bull; Practice your answers to common interview questions.</p>
            <p>&bull; Dress for success.</p>
            <p>&bull; Arrive on time.</p>
            <p>The<a className="underline text-blue-500" href="https://careers.workopolis.com/advice/study-how-quickly-do-interviewers-really-make-decisions/#:~:text=Some%20of%20the%20interviewers%20did,after%20five%20minutes%20or%20longer.">&nbsp;studies</a> show that most employers make their decision within the first five minutes of an interview. So, it&apos;s important to make a good impression from the start.</p>
            <h2 className="font-bold text-2xl my-3">7. Stay positive and motivated.<img src="https://lh4.googleusercontent.com/LA2Zf6Lg3kTgZLMUEW85B9nsR-7cb_XUXhAiMcHMvkytxbw8XEnHrTWIIaSEdABP7ImE5nIBGUjVYHtM49WV2N2Li_aluR-tdFtIrayDMReTjxEXFi4mHj5rHtXKoOdL37EkeKG_" width="427" height="281" style="float: right; text-align: right; display: inline-block; "></h2>
            <p>The job-hunting process can be frustrating and discouraging, but it&apos;s important to stay positive and motivated. Keep in mind that the process takes time and there&apos;s no one-size-fits-all solution.</p>
            <h3 className="font-bold text-2xl my-3">What to do when feeling down during the job hunt?</h3>
            <p>Some tips for staying positive and motivated during the job-hunt include:</p>
            <p>&bull; Set realistic goals.</p>
            <p>&bull; Take care of yourself.</p>
            <p>&bull; Stay connected with your network.</p>
            <p>&bull; Seek professional help if needed.</p>
            <p>According to a recent<a className="underline text-blue-500" href="https://www.flexjobs.com/blog/post/how-long-should-a-job-search-take/#:~:text=A%202018%20study%20also%20found,participate%20in%20five%20job%20interviews.">&nbsp;study</a>, the average job search takes about five months. So be patient and stay positive - your dream job is out there!</p>
            <h2 className="font-bold text-2xl my-3">Conclusion</h2>
            <p>The job-hunting process can be long and daunting, but with the right tips and advice, you&apos;ll be on your way to finding the perfect position for you. The seven tips we&apos;ve provided are from career experts who know what it takes to find a job in a short amount of time. Utilize their advice and you&apos;ll be one step closer to landing your dream job!</p>
            <p>If you&rsquo;re looking for a job, we&rsquo;ve got something perfect for you!<a className="underline text-blue-500" href="https://blackuptech.com/">&nbsp;BlackUp</a> is the perfect tool for job seekers looking for a non-biased A.I. &amp; ML algorithm that matches job openings to Black professional applicants. With BlackUp, you can easily search and apply for jobs that best match your qualifications. Don&apos;t miss out on this unique opportunity - sign up today!</p>
            <p><br></p>`
        }
    ]

    const redirect = (id) => {
        navigate(`/blogs/${id}`);
    }
    return (
        <div className='px-10 lg:px-20 mt-20'>

            <p className='text-3xl lg:text-54 lg:leading-60 font-bold'>{blogContent[id].title}</p>

            <img src={blogContent[id].poster} alt="" className='w-full rounded-3xl mt-10' />

            <p className='text-secondary mt-16'>{HTMLReactParser(blogContent[id].content)}</p>

            <p className='font-bold text-3xl my-20'>Suggested Blogs</p>

            <div className='grid grid-cols-6 md:gap-20 mt-10 lg:mt-20'>
                {blogs.map((blog, i) => {
                    return (
                        <div key={i} className='bg-white col-span-6 md:col-span-3 lg:col-span-2 rounded-2xl mt-10 md:mt-0' onClick={() => redirect(i)}>
                            <img src={blog.poster} alt="Blog poster" className='w-full' />
                            <div className='px-6'>
                                <p className='font-bold text-2xl mt-6'>{blog.title}</p>
                                <div className='flex items-center space-x-6 mt-6'>
                                    <img src={blog.authorProfile} alt="Author Profile" className='rounded-full' />
                                    <p className='text-secondary text-lg font-bold'>{blog.author}</p>
                                </div>
                                <p className='font-bold text-lg mt-4'>{blog.date}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Blog
